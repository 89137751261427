import dotenv from 'dotenv';

import React, { useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import Countdown from './components/countdown';
import './App.css';
dotenv.config();
function App() {
  const [login, setLogin] = useState(false);
  const [data, setData] = useState({});
  const [picture, setPicture] = useState('');

  const responseFacebook = (response) => {
    console.log(response);
    setData(response);
    setPicture(response.picture.data.url);
    if (response.accessToken) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }

  return (
    <div className="App">
      <header className="">
        {/*login ?
          <img src={picture} alt='' /> : <FacebookLogin
            textButton="fortsæt med facebook"
            appId="561232831660610"
            autoLoad={true}
            fields="name,email,picture"
            scope="public_profile"
            callback={responseFacebook}
            icon="fa-facebook" />*/
        }
      </header>
      <Countdown timeTillDate="01 12 2022, 09:00" timeFormat="DD MM YYYY, hh:mm" />
      {login &&
        <div>
          <p>{data.name}</p>
          <p>
            {data.email}
          </p>
        </div>
      }
    </div>
  );
}

export default App;
