import React, { useEffect, useState } from 'react';
import './countdown.scss'
import moment from 'moment';

function Countdown({ timeTillDate, timeFormat }) {
  const [months, setMonths] = useState('');
  const [days, setDays] = useState('');
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const then = moment(timeTillDate, timeFormat);
      const now = moment();
      const countdown = moment.duration(then.diff(now));
      const months = countdown.get('months');
      const days = countdown.get('days');
      const hours = countdown.get('hours');
      const minutes = countdown.get('minutes');
      const seconds = countdown.get('seconds');
      setMonths(months);
      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds)
    }, 1000);
    return () => clearInterval(interval);
  }, [timeTillDate, timeFormat]);

  const monthsRadius = mapNumber(months, 12);
  const daysRadius = mapNumber(days, 30);
  const hoursRadius = mapNumber(hours, 24);
  const minutesRadius = mapNumber(minutes, 60);
  const secondsRadius = mapNumber(seconds, 60);

  return (
    <div>
      <h1>Nedtælling</h1>
      <h2>Læs om Skovnissen Nis's eventyr <br />og hvordan han redder prinsesse Neya fra en frygtelig skæbne</h2>
      <div className='countdown-wrapper'>
        {months && (
          <div className='countdown-item'>
            <SVGCircle radius={monthsRadius} />
            {months}
            <span>months</span>
          </div>
        )}
        {days && (
          <div className='countdown-item'>
            <SVGCircle radius={daysRadius} />
            {days}
            <span>days</span>
          </div>
        )}
        {hours && (
          <div className='countdown-item'>
            <SVGCircle radius={hoursRadius} />
            {hours}
            <span>hours</span>
          </div>
        )}
        {minutes && (
          <div className='countdown-item'>
            <SVGCircle radius={minutesRadius} />
            {minutes}
            <span>minutes</span>
          </div>
        )}

        <div className='countdown-item'>
          <SVGCircle radius={secondsRadius} />
          {seconds}
          <span>seconds</span>
        </div>

      </div>
      <h3>Starter 1. december 2022 kl 9</h3>

    </div>
  )
}

const SVGCircle = ({ radius }) => (
  <svg className='countdown-svg'>
    <path fill="none" stroke="#c44394" stroke-width="4" d={describeArc(50, 50, 48, 0, radius)} />
  </svg>
);

// From stackoverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  };
}

function describeArc(x, y, radius, startAngle, endAngle) {

  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  var d = [
    "M", start.x, start.y,
    "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
  ].join(" ");

  return d;
}

// Stackoverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
function mapNumber(number, in_min) {
  return (number - in_min) * (360 - 0) / (0 - in_min) + 0;
}

export default Countdown;
